.graph {
  text-align: center;
  align-items: center;
  align-content: center;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #22333b;
  justify-content: center;
  display: flex;
}

.homeGraph {
  text-align: center;
  align-items: center;
  align-content: center;
  padding: 30px;
  border-radius: 10px;
  justify-content: center;
  display: flex;
}

.home-para {
  font-family: "Helvetica Neue", serif;
  font-weight: 350;
}

h1 {
  font-family: "Baskerville", "Times New Roman", serif;
  color: #22333b;
}

h3 {
  font-family: "Helvetica Neue", serif;
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
  color: #22333b;
}

.about-chronolog {
  font-family: Helvetica, sans-serif;
}

#chronolog-header {
  padding: 60px 0 0;
}

.bookViz h1 {
  text-align: center;
  padding: 10px;
  padding-bottom: 0%;
  font-size: 40pt;
}

.color-nav {
  background-color: #22333b;
  font-family: "Baskerville", "Times New Roman", serif;
  font-weight: 500;
}

.nav-brand {
  font-size: 16pt;
  /*color: #22333b !important;*/
  text-decoration: underline !important;
}

.nav-link {
  color: #c6ac8f !important;
}

.books {
  padding: 50px 50px 50px 50px;
}

.card-img-top {
  width: 100%;
  height: 25vw;
  object-fit: contain;
  padding: 10px 10px 10px 10px;
}

.about h4 {
  padding-right: 20px;
}

.about a {
  color: #22333b;
  text-decoration: underline;
}

.about a:hover {
  color: blue;
}

section {
  display: flexbox;
  /* border: #22333b;
    border-style: solid; */
  margin-left: 20%;
  height: 100vh;
  padding-top: 2%;
  padding-right: 5%;
  font-size: 20px;
}

#chronolog {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

#graphs {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

#graph_stats {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.example_graph_1 {
  border: #22333b;
  border-radius: 10px;
  border: 1px solid #22333b;
  width: 500px;
  height: 300px;
  display: flex;
}

.example_graph_2 {
  border: #22333b;
  border-radius: 10px;
  border: 1px solid #22333b;
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  float: right;
}

.graph_explaination_1 {
  padding-left: 10px;
  float: right;
}

.graph_explaination_2 {
  padding-bottom: 10px;
}

.graph_node_demo {
  display: flex;
}
.card_demo {
  display: flex;
  padding-bottom: 20px;
}

.graph_link_demo {
  padding-top: 20px;
}

.slide_explaination {
  align-self: center;
  text-align: center;
}

.example_slider {
  border: #22333b;
  border-radius: 10px;
  border: 1px solid #22333b;
  width: 500px;
  height: 300px;
  display: flex;
  justify-content: center;
  place-items: center;
}

.about_card {
  justify-content: center;
  width: 600px;
  height: -webkit-max-content;
  height: max-content;
}

.sidebar {
  position: fixed;
  display: flexbox;
  float: left;
  align-content: center;
  color: #22333b;
  padding: 10px;
  padding-left: 10px;
  top: 25%;
  border-right-style: solid;
  border-color: #c6ac8f;
}

.sidebar li {
  list-style: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.sidebar li:hover {
  cursor: pointer;
  text-decoration: underline;
}

.active {
  font-weight: bold;
  text-decoration: none;
  color: #22333b;
}

.nav {
  justify-content: center;
}

.graph {
  position: center;
  /*right: 2%;*/
  top: 10%;
  margin: auto;
}

