.about h4 {
  padding-right: 20px;
}

.about a {
  color: #22333b;
  text-decoration: underline;
}

.about a:hover {
  color: blue;
}

section {
  display: flexbox;
  /* border: #22333b;
    border-style: solid; */
  margin-left: 20%;
  height: 100vh;
  padding-top: 2%;
  padding-right: 5%;
  font-size: 20px;
}

#chronolog {
  height: fit-content;
}

#graphs {
  height: fit-content;
}

#graph_stats {
  height: fit-content;
}

.example_graph_1 {
  border: #22333b;
  border-radius: 10px;
  border: 1px solid #22333b;
  width: 500px;
  height: 300px;
  display: flex;
}

.example_graph_2 {
  border: #22333b;
  border-radius: 10px;
  border: 1px solid #22333b;
  width: max-content;
  display: flex;
  float: right;
}

.graph_explaination_1 {
  padding-left: 10px;
  float: right;
}

.graph_explaination_2 {
  padding-bottom: 10px;
}

.graph_node_demo {
  display: flex;
}
.card_demo {
  display: flex;
  padding-bottom: 20px;
}

.graph_link_demo {
  padding-top: 20px;
}

.slide_explaination {
  align-self: center;
  text-align: center;
}

.example_slider {
  border: #22333b;
  border-radius: 10px;
  border: 1px solid #22333b;
  width: 500px;
  height: 300px;
  display: flex;
  justify-content: center;
  place-items: center;
}

.about_card {
  justify-content: center;
  width: 600px;
  height: max-content;
}

.sidebar {
  position: fixed;
  display: flexbox;
  float: left;
  align-content: center;
  color: #22333b;
  padding: 10px;
  padding-left: 10px;
  top: 25%;
  border-right-style: solid;
  border-color: #c6ac8f;
}

.sidebar li {
  list-style: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.sidebar li:hover {
  cursor: pointer;
  text-decoration: underline;
}

.active {
  font-weight: bold;
  text-decoration: none;
  color: #22333b;
}
