.graph {
  text-align: center;
  align-items: center;
  align-content: center;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #22333b;
  justify-content: center;
  display: flex;
}

.homeGraph {
  text-align: center;
  align-items: center;
  align-content: center;
  padding: 30px;
  border-radius: 10px;
  justify-content: center;
  display: flex;
}

.home-para {
  font-family: "Helvetica Neue", serif;
  font-weight: 350;
}

h1 {
  font-family: "Baskerville", "Times New Roman", serif;
  color: #22333b;
}

h3 {
  font-family: "Helvetica Neue", serif;
  font-variant: small-caps;
  color: #22333b;
}

.about-chronolog {
  font-family: Helvetica, sans-serif;
}

#chronolog-header {
  padding: 60px 0 0;
}

.bookViz h1 {
  text-align: center;
  padding: 10px;
  padding-bottom: 0%;
  font-size: 40pt;
}
